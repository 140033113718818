body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
  overflow-x: hidden;
  background-color: #f7fafc;
}

h1, h2, h3, h4, h5, h6, p, span {
  color: #4A5568;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  outline: none !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #C2CEDB;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.261);
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.261);
}