.App {
  text-align: center;
  height: 100%;
  font-size: 13px;
  font-family: Inter;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-brand-header {
  width: 200px;
}

.App-brand-header-brand {
  font-size: 16px;
  font-weight: bold;
  color: #F2911B !important;
}

.App-brand-header-subtitle {
  font-size: 9px;
  color: #BAB7B7;
  text-align: right;
  margin-right: 1.5rem;
}

.main-area {
  background-color: #F3F5F9;
  height: 100%;
  text-align: center;
}

.main-area-contents {
  background-color: #F3F5F9;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  max-width: 800px;
}

.theme-nav-menu-item {
  font-size: 13px;
  font-weight: bold;
  color: #BAB7B7;
}

.theme-nav-menu-item {
  font-size: 13px;
  font-weight: bold;
  color: #BAB7B7;
}

.theme-content-menu-item {
  font-size: 13px;
  font-weight: bold;
  color: darkgray !important;
}

.theme-content-menu-divider {
  border: 1px solid #CFCDCD;
}

.theme-modal-dialog {
  text-align: center;
  height: 100%;
  font-size: 13px;
}

.theme-input {
  border: 0px;
  background-color: white;
  border-radius: 4px;
  min-width: 340px;
  border-color: #BAB7B7;
  border-style: solid;
  border-width: 0px;
  min-height: 30px;
  font-size: 13px;
}

.theme-input-border {
  border: 0px;
  background-color: white;
  border-radius: 4px;
  min-width: 340px;
  border-color: #BAB7B7;
  border-style: solid;
  border-width: 1px;
  min-height: 30px;
  font-size: 13px;
}

.theme-row {
  margin-top: 10px;
}

.theme-button {
  border-width: 0px;
}

.theme-button-icon {
  width: 40px;
  height: auto;
  color: #DCE0E2;
  margin-left: 0.5rem;
}

.theme-sidebar {
  border-radius: 3px;
  max-width: 240px;
}

.theme-sidebar-list-title {
  text-align: left;
  color: darkgray;
}

.theme-sidebar-list {
  background-color: white;
  border-radius: 3px;
}

.theme-sidebar-input {
  border: 0px;
  background-color: white;
  border-radius: 4px;
  min-width: 180px;
  border-color: #BAB7B7;
  border-style: solid;
  border-width: 0px;
  min-height: 25px;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.theme-event-list {
  background-color: white;
  border-radius: 3px;
  max-height: 400px;
}

theme-event-list-item {
  font-size: 8px;
}

.theme-sidebar-menu-item {
  font-size: 12px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

a:link, a:visited {
  /*background-color: #f44336;*/
  color: blue;
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

a:hover, a:active {
  /*background-color: red;*/
  color: #282c34 !important;
}

.theme-menu-item-active {
  /*background-color: red;*/
  color: #282c34 !important;
}

.theme-menu-item-inactive {
  color: #CFCDCD !important;
}

.theme-error-message-box {
  color: lightcoral;
  height: 40px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}